/* eslint-disable */
/**
 * 主题配置信息
 * 
 * hasBuild 是否已构建？
 *  true ：已构建，不再构建
 *  false：未构建，执行命令后会自动构建
 */
module.exports = [
  { name: 'default', color: '#2F80ED', desc: '默认色', hasBuild: false },
  { name: 'cyan', color: '#0BB2D4', desc: '青色', hasBuild: false },
  { name: 'blue', color: '#4A90E2', desc: '蓝色', hasBuild: false },
  { name: 'green', color: '#11C26D', desc: '绿色', hasBuild: false },
  { name: 'turquoise', color: '#17B3A3', desc: '蓝绿色', hasBuild: false },
  { name: 'indigo', color: '#667AFA', desc: '靛青色', hasBuild: false },
  { name: 'brown', color: '#997B71', desc: '棕色', hasBuild: false },
  { name: 'purple', color: '#9463F7', desc: '紫色', hasBuild: false },
  { name: 'gray', color: '#757575', desc: '灰色', hasBuild: false },
  { name: 'orange', color: '#EB6709', desc: '橙色', hasBuild: false },
  { name: 'pink', color: '#F74584', desc: '粉红色', hasBuild: false },
  { name: 'yellow', color: '#FCB900', desc: '黄色', hasBuild: false },
  { name: 'red', color: '#FF4C52', desc: '红色', hasBuild: false }
]

// 修改系统顶部的背景色：public/element-theme/default/aui.css 的aui-navbar