<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__dtsvideomonitor">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.pointName" placeholder="视频控制点名称" clearable ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('opm:cctv:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('opm:cctv:delete')" @click="deleteHandle()"></toolbar-button>
            <toolbar-button role="export" v-if="$hasPermission('opm:cctv:export')" @click="exportHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;">
        <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
        <el-table-column prop="pointName" label="视频监控点名称" min-width="150" header-align="center" align="left" sortable="custom" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="viewHandle(scope.row.id)">{{scope.row.pointName}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="liveUrl" label="视频直播地址" width="300" header-align="center" align="left" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="x" label="x" width="110" header-align="center" align="right" sortable="custom"  show-overflow-tooltip></el-table-column>
        <el-table-column prop="y" label="y" width="110" header-align="center" align="right" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="z" label="z" width="110" header-align="center" align="right" sortable="custom"  show-overflow-tooltip></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="160">
          <template v-slot="{row}">
            <table-button role="update" v-if="$hasPermission('opm:cctv:update')" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('opm:cctv:delete')" @click="deleteHandle(row.id)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('opm:cctv:update'))"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './cctv-add-or-update'
import ViewPage from './cctv-view'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/opm/cctv/page',
        getDataListIsPage: true,
        exportURL: '/opm/cctv/export',
        deleteURL: '/opm/cctv',
        createdIsNeed: false
      },
      dataForm: {
        pointName: '',
        contractId: null,
      },
    }
  },
  watch: {
  },
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    // 合同标段切换事件
    contractChange (data) {
      this.dataForm.contractId = data.contractId
      this.getDataList()
    },

    // 新增 / 修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
        this.$refs.addOrUpdate.init()
      })
    },
  }
}
</script>