<template>
  <div v-loading.fullscreen.lock="loading" :element-loading-text="$t('loading')" :class="['aui-wrapper', { 'aui-sidebar--fold': $store.state.sidebarFold }]">
    <template v-if="!loading">
      <main-navbar />
      <main-sidebar ref="mySidebar"></main-sidebar>
      <div class="aui-content__wrapper">
        <main-content v-if="!$store.state.contentIsNeedRefresh" />
        <!-- 全局共用组件：附件上传抽屉组件 -->
        <my-upload-drawer ref="myUploadDrawer"></my-upload-drawer>
      </div>
      <main-theme-tools />
    </template>
  </div>
</template>

<script>
import MainNavbar from './main-navbar'
import MainSidebar from './main-sidebar'
import MainContent from './main-content'
import MainThemeTools from './main-theme-tools'
import debounce from 'lodash/debounce'
import { isURL } from '@/utils/validate'
import { themeColorChangeHandle } from '@/utils'
// 附件上传抽屉
import MyUploadDrawer from '@/components/my-upload-drawer'
export default {
  provide () {
    return {
      // 局部刷新当前页签中的页面内容
      refresh () {
        this.$store.state.contentIsNeedRefresh = true
        this.$nextTick(() => {
          this.$store.state.contentIsNeedRefresh = false
        })
      }
    }
  },
  data () {
    return {
      loading: true
    }
  },
  components: {
    MainNavbar,
    MainSidebar,
    MainContent,
    MainThemeTools,
    MyUploadDrawer
  },
  watch: {
    $route: 'routeHandle'
  },
  mounted() {
    // 给附件上传抽屉组件绑定全局事件总线，方便在任何组件中使用
    this.$bus.$on('showUploadDrawer', (refId, types, readOnly, title, onclose, hideDefaultTypeMenu) => {
      this.$refs.myUploadDrawer.loadData(refId, types, readOnly, title, onclose, hideDefaultTypeMenu)
    })
    // 为左侧模块菜单子组件绑定事件，用于在切换子系统时刷新左侧显示的模块和菜单
    this.$bus.$on('refreshSidebarMenus', (subsystemId) => {
      if (this.$refs.mySidebar) {
        this.$refs.mySidebar.refreshSidebarMenus(subsystemId)
      }
    })
  },
  beforeDestroy() {
    // 销毁时必须解绑事件，否则再次登录时重复绑定
    this.$bus.$off('showUploadDrawer')
    this.$bus.$off('refreshSidebarMenus')
  },
  created () {
    // 从缓存中获取当前皮肤，并动态引入样式文件
    // let _themeColor = localStorage.getItem('themeColor')
    // if (typeof (_themeColor) === 'undefined' || _themeColor == null) {
    //   _themeColor = 'turquoise'
    // }
    
    // 【2023年9月改造】东莞路桥与数汇门户系统风格保持一致
    let _themeColor = 'default'

    themeColorChangeHandle(_themeColor)
    this.windowResizeHandle()
    this.routeHandle(this.$route)
    Promise.all([
      this.getUserInfo(),
      this.getPermissions()
    ]).then(() => {
      this.loading = false
    })
  },
  methods: {
    // 窗口改变大小
    windowResizeHandle () {
      this.$store.state.sidebarFold = document.documentElement['clientWidth'] <= 992 || false
      window.addEventListener('resize', debounce(() => {
        this.$store.state.sidebarFold = document.documentElement['clientWidth'] <= 992 || false
      }, 150))
    },
    // 路由, 监听
    routeHandle (route) {
      if (!route.meta.isTab) {
        return false
      }
      let tab = {}
      let routeName = route.name
      let routeMeta = route.meta
      if (route.name === 'iframe') {
        let url = route.query.url || ''
        if (!isURL(url)) {
          return false
        }
        let key = route.query.key || new Date().getTime()
        routeName = `${routeName}_${key}`
        routeMeta.title = key.toString()
        routeMeta.menuId = route.query.menuId || ''
        routeMeta.iframeURL = url
      }
      tab = this.$store.state.contentTabs.filter(item => item.name === routeName)[0]
      if (!tab) {
        tab = {
          ...window.SITE_CONFIG['contentTabDefault'],
          ...routeMeta,
          'name': routeName,
          'params': { ...route.params },
          'query': { ...route.query }
        }
        this.$store.state.contentTabs = this.$store.state.contentTabs.concat(tab)
      }
      this.$store.state.sidebarMenuActiveName = tab.menuId
      this.$store.state.contentTabsActiveName = tab.name
      // // 路由改变时刷新页面
      // this.$store.state.contentIsNeedRefresh = true
      // this.$nextTick(() => {
      //   this.$store.state.contentIsNeedRefresh = false
      // })
    },
    // 获取当前用户信息
    getUserInfo () {
      return this.$http.get('/sys/user/openapi/info').then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$store.state.user.id = res.data.id
        this.$store.state.user.name = res.data.username
        this.$store.state.user.realName = res.data.realName
        this.$store.state.user.superAdmin = res.data.superAdmin
        this.$store.state.user.superTenant = res.data.superTenant
        this.$store.state.user.tenantName = res.data.tenantName
        this.$store.state.user.headUrl = res.data.headUrl
        this.$store.state.user.typeId = res.data.typeId
      }).catch(() => {})
    },
    // 获取当前用户权限
    getPermissions () {
      return this.$http.get('/sys/menu/permissions').then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        window.SITE_CONFIG['permissions'] = res.data
      }).catch(() => {})
    }
  }
}
</script>
