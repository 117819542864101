<template>
  <div class="aui-theme-tools" :class="{ 'aui-theme-tools--open': isOpen }">
    <div class="aui-theme-tools__toggle" v-if="enableThemeTools" @click="isOpen = !isOpen">
      <svg class="icon-svg" aria-hidden="true"><use xlink:href="#icon-setting"></use></svg>
    </div>
    <div class="aui-theme-tools__content">
      <div class="aui-theme-tools__item">
        <h3>顶部样式</h3>
        <el-checkbox v-model="navbarLayoutType" true-label="colorful">colorful 鲜艳</el-checkbox>
      </div>
      <div class="aui-theme-tools__item">
        <h3>左侧样式</h3>
        <el-checkbox v-model="sidebarLayoutSkin" true-label="dark">dark 黑色</el-checkbox>
      </div>
      <div class="aui-theme-tools__item">
        <h3>系统主题</h3>
        <el-radio-group v-model="themeColor" @change="themeColorChangeHandle">
          <el-radio v-for="item in themeList" :key="item.name" :label="item.name">{{ `${item.name} ${item.desc}` }}</el-radio>
        </el-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isOpen: false,
      themeList: require('@/element-ui/config.js'),
      themeColor: 'blue',
      navbarLayoutType: 'colorful',
      sidebarLayoutSkin: '',
      // 为了东莞路桥与数汇风格一致，暂时屏蔽右侧皮肤切换功能
      enableThemeTools: false,
    }
  },
  created () {
    // 从缓存中取出themeColor
    // let _themeColor = localStorage.getItem('themeColor')
    // if (typeof (_themeColor) === 'undefined' || _themeColor == null) {
    //   _themeColor = 'turquoise'
    // }
    // this.themeColor = _themeColor
    // // 从缓存中取出navbarLayoutType
    // let _navbarLayoutType = localStorage.getItem('navbarLayoutType')
    // if (typeof (_navbarLayoutType) === 'undefined' || _navbarLayoutType == null) {
    //   _navbarLayoutType = 'colorful'
    // }
    // this.navbarLayoutType = _navbarLayoutType
    // // 从缓存中取出sidebarLayoutSkin
    // let _sidebarLayoutSkin = localStorage.getItem('sidebarLayoutSkin')
    // if (typeof (_sidebarLayoutSkin) === 'undefined' || _sidebarLayoutSkin == null) {
    //   _sidebarLayoutSkin = 'dark'
    // }
    // this.sidebarLayoutSkin = _sidebarLayoutSkin

    // TODO 2023年9月改造，屏蔽皮肤切换功能，直接使用指定皮肤，使其与数汇门户风格尽可能一致。
    this.themeColor = 'default'
    this.navbarLayoutType = 'colorful'
    this.sidebarLayoutSkin = ''
  },
  watch: {
    navbarLayoutType (val) {
      // 将navbarLayoutType缓存起来
      this.$store.commit('setNavbarLayoutType', val)
    },
    sidebarLayoutSkin (val) {
      // 将sidebarLayoutSkin缓存起来
      this.$store.commit('setSidebarLayoutSkin', val)
    }
  },
  methods: {
    themeColorChangeHandle (val) {
      // 缓存
      localStorage.setItem('themeColor', val)
      var styleList = [
        {
          id: 'J_elementTheme',
          url: `${process.env.BASE_URL}element-theme/${val}/index.css?t=${new Date().getTime()}`
        },
        {
          id: 'J_auiTheme',
          url: `${process.env.BASE_URL}element-theme/${val}/aui.css?t=${new Date().getTime()}`
        }
      ]
      for (var i = 0; i < styleList.length; i++) {
        var el = document.querySelector(`#${styleList[i].id}`)
        if (el) {
          el.href = styleList[i].url
          continue
        }
        el = document.createElement('link')
        el.id = styleList[i].id
        el.href = styleList[i].url
        el.rel = 'stylesheet'
        document.querySelector('head').appendChild(el)
      }
    }
  }
}
</script>
