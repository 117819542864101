<!--附件上传抽屉组件-->
<template>
  <div>
    <el-drawer :title="title" :visible.sync="drawer" direction="rtl" :append-to-body="true" size="55%" @closed="drawerClose">

      <el-container style="border: 1px solid #eee">
        <!-- 左侧分类目录树 -->
        <el-aside width="200px" style="height: calc(100vh - 100px); border-right: solid 1px #e6e6e6;">
          <el-menu default-active="" @select="typeSelectHandle">
            <el-menu-item index="" v-if="!hideDefaultTypeMenu">
              <i class="el-icon-notebook-1"></i>
              <span slot="title">全部</span>
            </el-menu-item>
            <el-menu-item v-for="t in types" :index="t" :key="t">
              <i class="el-icon-notebook-2"></i>
              <span slot="title">{{t}}</span>
            </el-menu-item>
          </el-menu>
        </el-aside>

        <!-- 右侧附件列表 -->
        <el-container>
          <el-main style="padding-left: 1px; padding-right: 1px;">
            <el-upload
              v-if="!viewOnly"
              ref="uploader"
              multiple
              :disabled="viewOnly"
              :data="dataForm"
              :action="actionUrl"
              :file-list="fileList"
              :show-file-list="false"
              :before-upload="beforeFileUpload"
              :on-progress="onProgressHandle"
              :on-success="handleSuccess"
              :auto-upload="true"
            >
              <el-button slot="trigger" size="mini" type="primary" style="padding: 5px 10px">上传文件</el-button>
              <el-button size="mini" type="danger" @click="abortUpload" style="margin-left: 10px; padding: 5px 10px">取消上传</el-button>
            </el-upload>
            
            <el-table
              v-loading="dataListLoading"
              :data="dataList"
              highlight-current-row border
              @selection-change="dataListSelectionChangeHandle"
              @sort-change="dataListSortChangeHandle"
              style="width: 100%;"
              :header-cell-style="{color:'#3B3B3B'}">
              <el-table-column prop="name" label="附件名称" header-align="center" align="left" show-overflow-tooltip sortable="custom">
                <template slot-scope="scope">
                  <el-button type="text" @click="handleDownload(scope.row.url,scope.row)">{{ scope.row.name }}</el-button>
                </template>
              </el-table-column>
              <el-table-column prop="createDate" label="上传时间" header-align="center" align="center" show-overflow-tooltip sortable="custom" width="140"></el-table-column>
              <el-table-column prop="creatorName" label="上传人" header-align="center" align="center" show-overflow-tooltip width="110"></el-table-column>
              <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="120">
                <template slot-scope="scope">
                  <el-button v-if="!viewOnly && canDelete(scope.row)" type="text" size="small" @click="deleteHandle(scope.row.id)">{{ $t('delete') }}</el-button>
                  <el-button type="text" size="small" @click="openUploadDownloadRecordHandle(scope.row.id)">下载记录</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              :current-page="page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="limit"
              :total="total"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="pageSizeChangeHandle"
              @current-change="pageCurrentChangeHandle">
            </el-pagination>
          </el-main>
        </el-container>
      </el-container>

      <div style="left:45%;position:absolute;top: 44%;font-size:50px;width: 100%;z-index:10086;color: #1890ff;" v-show="isShowLoading">
        <div class="box">
          <div class="load" id="my_upload_drawer_load"></div>
        </div>
        <span id='my_upload_drawer_result'></span>
      </div>
    </el-drawer>
    <my-upload-download-record v-if="uploadDownloadRecordVisible" ref="uploadDownloadRecord" :ossId="currentClickId" @close="closeUploadDownloadRecordDialogHandle"></my-upload-download-record>
  </div>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import Cookies from 'js-cookie'
export default {
  name: 'MyUploadDrawer',
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: false,
        getDataListURL: '/oss/file/page',
        getDataListIsPage: true,
        deleteURL: '/oss/file',
      },
      viewOnly: true,
      types: [],
      hideDefaultTypeMenu: false,
      dataForm: {
        refId: '',
        statusId: 1,
        typeId: ''
      },
      isShowLoading: false, // 百分比div展示判断
      uploadingNum: 0,
      drawer: false,
      actionUrl: '',
      fileList: [],
      title: '附件管理',
      //successFileList: []
      onclose: undefined,

      // 附件下载记录
      uploadDownloadRecordVisible: false,
      currentClickId: '',
    }
  },
  created: function () {
    this.init()
  },
  methods: {
    init () {
      this.fileList = []
      //this.successFileList = []
      this.dataListLoading = false
      this.isShowLoading = false
      this.dataForm.refId = ''
      this.types = []
      this.title = '附件管理'
      //this.getDataList()
    },
    // 根据业务表ID重新加载附件列表
    loadData (refId,types,readOnly,title,onclose,hideDefaultTypeMenu) {
      this.dataForm.refId = refId
      this.types = types
      this.viewOnly = readOnly
      if (!title) {
        title = '附件管理'
      }
      if(hideDefaultTypeMenu) {
        this.hideDefaultTypeMenu = hideDefaultTypeMenu
        // 如果不显示“全部”选项，则不允许不带类型查询附件
        if (types && types.length > 0) {
          this.dataForm.typeId = types[0]
        } else {
          // 不显示“全部”选项时，又没有传入类型参数，则设置一个错误的类型参数，避免后台把全部附件都查询出来
          this.dataForm.typeId = new Date().getTime()
        }
      }
      this.title = title
      this.actionUrl = `${window.SITE_CONFIG['apiURL']}/oss/file/upload?access_token=${Cookies.get('access_token')}&tenant_code_key=${Cookies.get('tenantCode')}`
      this.getDataList()
      this.drawer = true
      this.onclose = onclose
    },
    // 上传前，让表格loading，让百分比div展示
    beforeFileUpload () {
      this.dataListLoading = true
      this.isShowLoading = true
    },
    // 上传中，显示上传进度
    onProgressHandle (event,file,fileList) {
      let progress = parseInt(event.percent)
      if (progress >= 100) {
        progress = 98
      }
      this.displayProgress(progress)
    },
    // 上传后，表格loading结束，让百分比div消失，清除定时器
    handleSuccess (res,file,fileList) {
      // 每个文件上传完成（出现对号）后都会调用 handleSuccess
      //this.successFileList = fileList
      this.displayProgress(100)
      this.dataListLoading = false
      this.isShowLoading = false
      this.getDataList()
    },
    // 动态显示百分比
    displayProgress (val) {
      let result = document.getElementById('my_upload_drawer_result')
      let load = document.getElementById('my_upload_drawer_load')
      result.innerText = val + "%"
      load.style.width = val * 3 + 'px'
    },
    // 取消上传
    abortUpload () {
      this.$refs.uploader.abort()
      this.dataListLoading = false
      this.isShowLoading = false
    },
    // 选中附件分类
    typeSelectHandle (index) {
      this.dataForm.typeId = index
      this.getDataList()
    },
    // 点击附件名称，下载附件时保存用户下载记录
    handleDownload (url,row) {
      if (row) {
        let downloadDataForm = {
          realName: this.$store.state.user.realName,
          ossId: row.id
        }
        this.$http['post']('/oss/download/',downloadDataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
        }).catch(() => {
          // do nothing
        })
      }
      // 如果url为externalUrl则不需要转换为signedUrl
      if (row && row.props) {
        let props = JSON.parse(row.props)
        if (props.externalUrl == 'yes') {
          window.open(url)
          return
        }
      }
      this.$http.get(`/oss/file/signedUrl?url=${url}`)
        .then(({data: res}) => {
          if (res.code === 0 && res.data) {
            window.open(res.data)
          } else {
            this.$message({
              message: '对不起，未查询到相关文件！',
              type: 'error',
              duration: 5000
            })
          }
        }).catch(() => {
          // do nothing
        })
    },
    // 抽屉关闭事件
    drawerClose () {
      // 调用打开抽屉时传入的回调方法
      if (typeof this.onclose == 'function') {
        this.onclose()
      }
    },

    // 打开附件下载记录弹窗
    openUploadDownloadRecordHandle (ossId) {
      this.uploadDownloadRecordVisible = true
      this.$nextTick(() => {
        this.currentClickId = ossId
        this.$refs.uploadDownloadRecord.init()
      })
    },

    // 关闭附件下载记录弹窗
    closeUploadDownloadRecordDialogHandle () {
      this.uploadDownloadRecordVisible = false
      this.currentClickId = ''
    },
    // 根据props中的externalUrl属性判断是否可以删除
    canDelete(row) {
      if (row.props) {
        let props = JSON.parse(row.props)
        if (props.externalUrl == 'yes') {
          return false
        }
      }
      return true
    }
  }
}
</script>
<style>
  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #f5f5f5;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #c8c8c8;
  }
</style>