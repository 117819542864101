<template>
  <el-dialog
    :visible.sync="visible"
    title="个人信息"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :append-to-body="true">
    <el-form :model="dataForm" ref="dataForm" @keyup.enter.native="dataFormSubmitHandle()" label-width="120px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="用户姓名">
            <el-input disabled :value="$store.state.user.realName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="登录账号">
            <el-input disabled :value="$store.state.user.name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item prop="email" label="电子邮箱">
            <el-input v-model="dataForm.email" :placeholder="$t('user.email')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="mobile" label="手机号码">
            <el-input v-model="dataForm.mobile" :placeholder="$t('user.mobile')"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="用户头像">
        <my-avatar :avatarUrl="dataForm.headUrl" @setAvatarUrl="setAvatarUrl"></my-avatar>
      </el-form-item>
      <el-form-item label="上传签名">
        <my-avatar :avatarUrl="dataForm.signatureUrl" @setAvatarUrl="setSignatureUrl"></my-avatar>
      </el-form-item>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
import { clearLoginInfo } from '@/utils'
export default {
  data () {
    return {
      visible: false,
      // 用于替换头像时删除之前上传的旧头像
      oldHeadUrl: '',
      // 用于替换签名时删除之前上传的旧签名
      oldSignatureUrl: '',
      dataForm: {
        email: '',
        mobile: '',
        headUrl: '',
        signatureUrl: '',
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        this.getInfo()
      })
    },
    getInfo () {
      this.$http.get('/sys/user/openapi/info').then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        this.oldHeadUrl = this.dataForm.headUrl
        this.oldSignatureUrl = this.dataForm.signatureUrl
      }).catch(() => {
      })
    },
    setAvatarUrl (avatarUrl) {
      this.dataForm.headUrl = avatarUrl
    },
    setSignatureUrl (avatarUrl) {
      this.dataForm.signatureUrl = avatarUrl
    },
    dataFormSubmitHandle: debounce(function () {
      // 如果修改了头像，需要将上一次上传的旧头像删除
      if (this.oldHeadUrl !== this.dataForm.headUrl && this.oldHeadUrl !== '') {
        this.$http.delete('/oss/file/deleteByUrl?url=' + this.oldHeadUrl).then(({ data: res }) => {})
      }
      // 如果修改了签名，需要将上一次上传的旧签名删除
      if (this.oldSignatureUrl !== this.dataForm.signatureUrl && this.oldSignatureUrl !== '') {
        this.$http.delete('/oss/file/deleteByUrl?url=' + this.oldSignatureUrl).then(({ data: res }) => {})
      }
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http.put('/sys/user/openapi/myUserInfo', this.dataForm).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              // 暂时通过重新登录的方式解决SecurityUser的更新。
              clearLoginInfo()
              this.$router.replace({ name: 'login' })
            }
          })
        }).catch(() => {})
      })
    }, 1000, { 'leading': true, 'trailing': false })
  }
}
</script>
