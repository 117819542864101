<template>
  <el-dialog width="70%" v-dialog-drag :visible.sync="visible" title="在线用户" v-on="$listeners" :append-to-body="true">
    <vxe-table
      :row-config="{isHover: true,isCurrent: true,useKey: true}"
      :data="tableData"
      size="mini"
      :scroll-y="{enabled: false}">
      <!-- <vxe-column width="80" title="头像">
        <template #default="{ row }">
          <img :src="row.headUrl" height="80">
        </template>
      </vxe-column> -->
      <vxe-column field="realName" title="姓名"></vxe-column>
      <vxe-column field="createDate" title="登录时间"></vxe-column>
      <vxe-column field="duration" title="在线时长（分钟）"></vxe-column>
    </vxe-table>
  </el-dialog>
</template>
<script>
export default {
    data () {
      return {
        visible: false,
        tableData: [],
      }
    },
    methods: {
      init() {
        this.visible = true
        this.$nextTick(() => {
          this.$http.get('/sys/online/list').then(({ data: res }) => {
            if (res.code == 0) {
              this.tableData = res.data
              // 加载头像的signedUrl
              // if (this.tableData && this.tableData.length > 0) {
              //   for (let index = 0; index < this.tableData.length; index++) {
              //     const e = this.tableData[index];
              //     this.$http.get(`/oss/file/signedUrl?url=${e.headUrl}`).then(({ data: res }) => {
              //       if (res.code === 0) {
              //         e.headUrl = res.data
              //       } else {
              //         e.headUrl = require('../assets/img/avatar.png')
              //       }
              //       this.$set(this.tableData, index, e);
              //     })
              //     .catch(() => { })
              //   }
              // }
            }
          }).catch((e) => {
            this.$message.error('加载在线用户时出现异常：' + e)
          })
        })
      },
    },
  }
</script>
